// 引入 vue
import Vue from "vue";
// 引入 vuex
import Vuex from "vuex";
Vue.use(Vuex);
// 创建vuex实例
const store = new Vuex.Store({
    // 状态
    state: {
        storeUseChatList: [],
        collectList: [],
        LicenseKey: "6E5BB426-CB60-4D8C-9FBD-BA7D6913049C",
        productType: "fhsado7iweqrioehqn9klnagd",
        instance_id: "",
        gtpModel: localStorage.getItem("gtpModel"),
        chatTitle: "Welcome!",
        apiKey: "",
        lackLicense: false,
        // 侧边栏收起/放开
        $setAside: "translateX(0)",
        $mainPadding: "360px",
        fixedTitleColor: localStorage.getItem("fixedTitleColor"), //顶部栏颜色
        contentBgColor: "transparent", //会话区域颜色
        chatTitleStore: "#a6b0bd", //会话标题
        chargingStore: "#5d636b", //计费信息
        changetextfff: "#ffffff", //文字颜色
        userUploadIcon: localStorage.getItem("userUploadIcon"), //自己的头像
    },
    // 修改状态
    mutations: {
        //更新lackLicense 是否显示红色的开会员提示
        setLackLicense(state, data) {
            state.lackLicense = data;
        },
        //更新apiKey
        setapiKeyHandel(state, data) {
            state.apiKey = data;
            localStorage.setItem("apiKey", data);
        },
        //更新LicenseKey
        setLicenseKeyHandel(state, data) {
            state.LicenseKey = data;
            state.lackLicense = false;
            localStorage.setItem("LicenseKey", data);
        },
        //更新productType
        setProductTypeHandel(state, data) {
            state.productType = data;
            localStorage.setItem("productType", data);
        },
        //更新instance_id
        setInstanceIdHandel(state, data) {
            state.instance_id = data;
            localStorage.setItem("instance_id", data);
        },
        //保存会话列表
        setStoreUseChatList(state, data) {
            state.storeUseChatList = data;
            // 持久化存储
            localStorage.setItem("TM_useChatList", JSON.stringify(data));
        },
        // 更新storeUseChatList中的值
        updateSetStoreUseChatList(state, data) {
            state.storeUseChatList.forEach((x) => {
                if (x.chatID === data.chatID) {
                    x.chatTitle = data.chatTitle;
                    x.preview = data.preview;
                    let index = state.storeUseChatList.indexOf(x);
                    if (index >= 0) {
                        state.storeUseChatList.splice(index, 1); // 从原数组中移除该项
                        state.storeUseChatList.unshift(x); // 将该项插入到数组的首位
                    }
                }
            });
            localStorage.setItem(
                "TM_useChatList",
                JSON.stringify(state.storeUseChatList)
            );
        },
        // 更新storeUseChatList中的选中状态
        updataStoreUseChatListOn(state, data) {
            localStorage.setItem("chatID", data.chatID);
            state.storeUseChatList.forEach((x) => {
                x.on = false;
                if (x.chatID === data.chatID) {
                    x.on = true;
                }
            });
            state.storeUseChatList = [...state.storeUseChatList];
            localStorage.setItem(
                "TM_useChatList",
                JSON.stringify(state.storeUseChatList)
            );
        },
        //保存收藏列表
        setStoreCollectList(state, data) {
            state.collectList = data;
            // 持久化存储
            localStorage.setItem("collectList", JSON.stringify(data));
        },
        // 修改聊天标题
        saveChatTitle(state, data) {
            state.chatTitle = data;
        },
        // 修改侧边栏
        set$setAside(state, data) {
            state.$setAside = data;
        },
        set$mainPadding(state, data) {
            state.$mainPadding = data;
        },
        //更新自己头像
        setUserUploadIcon(state, data) {
            state.userUploadIcon = data;
            localStorage.setItem("userUploadIcon", data);
        },
        //修改文字颜色
        setTextColor(state, data) {
            if (state.fixedTitleColor == "#1E2224") {
                state.chatTitleStore = "#141718"; //标题颜色
                state.fixedTitleColor = "#F1F9FB"; //顶部栏颜色
                state.contentBgColor = "#FFFFFF"; //聊天区域背景颜色
                localStorage.setItem("fixedTitleColor", "#F1F9FB");
            } else {
                state.chatTitleStore = "#a6b0bd"; //标题颜色
                state.fixedTitleColor = "#1E2224"; //顶部栏颜色
                state.contentBgColor = "transparent"; //聊天区域背景颜色
                localStorage.setItem("fixedTitleColor", "#1E2224");
            }
        },
        // 保存gtp模型版本
        saveGtpModel(state, data) {
            state.gtpModel = data;
            localStorage.setItem("gtpModel", data);
        },

        //保存文字颜色
        saveTextColor(state, data) {
            if (data == "#1E2224") {
                // 深色模式
                state.chatTitleStore = "#a6b0bd"; //标题颜色
                state.fixedTitleColor = "#1E2224"; //顶部栏颜色
                state.contentBgColor = "transparent"; //聊天区域背景颜色
                (state.changetextfff = "#ffffff"), //文字颜色
                    localStorage.setItem("fixedTitleColor", "#1E2224");
            } else {
                state.chatTitleStore = "#141718"; //标题颜色
                state.fixedTitleColor = "#F1F9FB"; //顶部栏颜色
                state.contentBgColor = "#FFFFFF"; //聊天区域背景颜色
                (state.changetextfff = "#141718"), //文字颜色
                    localStorage.setItem("fixedTitleColor", "#F1F9FB");
            }
        },
    },
    actions: {

    },
    getters: {
        userChatList(state) {
            return [...state.storeUseChatList];
        },
        collectList(state) {
            return [...state.collectList];
        },
        LicenseKey(state) {
            return state.LicenseKey;
        },
        productType(state) {
            return state.productType;
        },
        instance_id(state) {
            return state.instance_id;
        },
        chatTitle(state) {
            return state.chatTitle;
        },
        apiKey(state) {
            return state.apiKey;
        },
        lackLicense(state) {
            return state.lackLicense;
        },
        $bgColor(state) {
            return state.$bgColor;
        },
        $textColor(state) {
            return state.$textColor;
        },
        $setAside(state) {
            return state.$setAside;
        },
        $mainPadding(state) {
            return state.$mainPadding;
        },
        fixedTitleColor(state) {
            return state.fixedTitleColor;
        },
        contentBgColor(state) {
            return state.contentBgColor;
        },
        chatTitleStore(state) {
            return state.chatTitleStore;
        },
        chargingStore(state) {
            return state.chargingStore;
        },
        changetextfff(state) {
            return state.changetextfff;
        },
        userUploadIcon(state) {
            return state.userUploadIcon;
        },
        gtpModel(state) {
            return state.gtpModel;
        },
    },
});

export default store;
