<template>
  <div id="app">
    <div id="nav">
      <router-view :key="key" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isshow: true,
      routerName: "",
    };
  },
  mounted() {
    //禁止任何键盘敲击事件（防止F12和shift+ctrl+i调起开发者工具）
    window.onkeydown =
      window.onkeyup =
      window.onkeypress =
        function (e) {
          if (e.ctrlKey && e.key == "f") {
            return false;
          }
        };
  },
  watch: {
    router: {
      handler(val) {
        this.routerName = val.name;
      },
      deep: true,
    },
  },
  computed: {
    router() {
      return this.$route;
    },
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    },
  },
  methods: {},
};
</script>
<style>
@import "assets/icon/iconfont.css";
@media (max-width: 450px) {
  /* 当设备屏幕宽度小于等于450像素时，应用其中定义的样式 */
}
@media (min-width: 650px) {
  /* 当设备屏幕宽度大于等于650像素时，应用其中定义的样式。 */
}
.btnOpacity9:hover {
  opacity: 0.9;
}
.content-page {
  background-color: #141718;
}
input,
textarea {
  touch-action: manipulation;
}
.transform {
  transition: background 0.5s; /* 过渡动画时间 */
}

.transformLeft {
  transition: transform 0.5s; /* 过渡动画时间 */
}
.transformAll {
  transition: all 0.5s; /* 过渡动画时间 */
}
.transformAll3 {
  transition: all 0.3s; /* 过渡动画时间 */
}
.transformAllPaddLeft {
  transition: none; /* 取消所有过渡效果 */
  transition-property: width, height, padding-left;
  transition-duration: 0.5s; /* 过渡动画时间 */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PingFang SC;
}
.underline:hover {
  text-decoration: underline;
}
body,
html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
div {
  box-sizing: border-box;
}
img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.span1 {
  padding: 0 5px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shou {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
}

input {
  outline: none;
  border: 0;
  outline: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
}

.flex_ {
  display: flex;
  align-items: center;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_ccenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.flex_around_ {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete {
  text-decoration: line-through;
}

.all_hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  flex-direction: column;
}
</style>
