import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import('@/views//faqs/faqs')
  }, 
]

const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   document.body.scrollTop = document.documentElement.scrollTop = 0
//   if (to.path === '/Login' || to.path === '/parse2') return next()
//   // 获取 token 值
//   if (to.path === '/user') {
//     const tokenStr = window.sessionStorage.getItem('uid')
//     if (!tokenStr) return next('/Login')
//     next()
//   }

//   next()
// })
export default router
