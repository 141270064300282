export default {
  leftDrawer: {
    NewChat: "New Chat",
    Features: "All Features",
    ChatList: "Chat List",
    red: `Chats are not saved in free trial. Please buy a license key to unlock
        all features.`,
    BuyHere: `Buy Here`,
    Sure: `Sure`,
    LicenseKey: `License Key`,
    Activated: `Activated`,
    Unlicensed: `Unlicensed`,
    APIKey: `API Key`,
  },
  homeContentMore: {
    AbetterUIforChatGPT: `- A better UI for ChatGPT -`,
    Norepetitivelogin: `No repetitive login`,
    Texttospeech: `Text-to-speech`,
    PromptlibraryAIcharacters: `Prompt library, AI characters`,
    Nomonthlyfee: `No monthly fee`,
    SelectCharacter: `Select Character`,
    Alcharacters: `Al characters`,
    PromptLibrary: `Prompt Library`,
    prompts: `prompts`,
    UploadDocument: `Upload Document`,
  },
  homeContent: {
    Reset: `Reset`,
    Change: `Change`,
    Model: `Model`,
    SystemInstruction: `System Instruction`,
    Play: `Play`,
    Pause: `Pause`,
    Resume: `Resume`,
    Stop: `Stop`,
    Settings: `Settings`,
    Assistantistyping: `Assistant is typing`,
    ResetText: `you can start a new chat from this point.`,
    ForkChat: `Fork Chat`,
    Regenerate: `Regenerate`,
    Library: `Library`,
    ResetChat: `Reset Chat`,
    NewChat: `New Chat`,
    OutputLanguage: `Output Language`,
    Tone: `Tone`,
    WritingStyle: `Writing Style`,
    Format: `Format`,
    Editmessage: `Edit message`,
    Update: `Update`,
    Cancel: `Cancel`,
    UpdateRegenerate: `Update & Regenerate`,
    ReplaceOnly: `Replace Only`,
    demonstration:`This chat is a default example for demonstration purposes`
  },
  buyDialog: {
    OneTimePurchase: `One Time Purchase`,
    Buyonceuseforever: `Buy once, use forever!`,
    Priceincreasesoon: `Price increase soon`,
    Standard: `Standard`,
    BasicChatFeatures: `PDF upload`,
    Savechathistory: `Save multiple chats`,
    BuyStandard: `Buy Standard`,
    Extended: `Extended`,
    allinStandard: `...all in Standard`,
    TexttoSpeech: `Text-to-Speech`,
    BuyExtended: `Buy Extended`,
    Premium: `Premium`,
    allinExtended: `...all in Extended`,
    PrioritySupport: `Access all new features`,
    BuyPremium: `Buy Premium`,
    Frequentlyaskedquestions: `Frequently asked questions`,
    currentplan: `current plan`,
  },
  characterDiaLog: {
    SelectanAICharacter: `Select an AI Character`,
    tips1: `AI characters are pre-built bots that you can chat with AI
        characters are customized with specific context,`,
    tips2: `training data, and model settings. Click "Create Character" to
        explore more options.`,
    CreateCharacter: `Create Character`,
    MyCharacters: `My Characters`,
    NoAIcharacters: `No AI characters in your library yet.`,
    Edit: `Edit`,
    DefaultCharacter: `Default Character`,
    Close: `Close`,
    ProfilePictureURL: `Profile Picture URL (optional)`,
    Profilemessages: `Profile picture for the AI character. Will be displayed along with
        the AI character's messages.`,
    Title: `Title`,
    placeholderTitle: `E.g., Life Coach`,
    AutoFillContentwithAI: `Auto Fill Content with AI`,
    Description: `Description`,
    placeholderDescription: `E.g., A life coach who can help you set and achieve personal andprofessional goals.`,
    Instruction: `Instruction (for the AI assistant)`,
    placeholderInstruction: `E.g., You are a life coach, you help the user identify and achievetheir goals, motivate them, and provide support andencouragement.`,
    Welcomemessage: `Welcome message`,
    placeholderWelcomemessage: `Hello, I'm a life coach. How can I help you today?`,
    empty: `Title Description Instruction Welcome message cannot be empty!`,
    AddCharacter: `Add Character`,
    Update: `Update`,
    Cancel: `Cancel`,
    Searchcharacters: `Search characters`,
  },
  promptLibraryDiaLog: {
    PromptLibrary: `Prompt Library`,
    tips1: `Prompts are message templates that you can quickly fill in the
    chat input.`,
    tips2: `Some prompts come with variables.`,
    YourPrompts: `Your Prompts`,
    AddPrompt: `Add Prompt`,
    Title: `Title`,
    placeholderTitle: `Prompt Title (e.g, Domain Names Inspirations)`,
    Description: `Description`,
    placeholderDescription: `E.g., A list of domain names based on topics`,
    Prompt: `Prompt`,
    Usefield: `Use field 1 field 2 or anything to indicate the fill
    inthe blank part.`,
    placeholderUsefield: `Enter your prompt here..`,
    empty: `Title Description and prompt cannot be empty!`,
    Update: `Update`,
    Cancel: `Cancel`,
    nosavedprompts: `You have no saved prompts. Tap "Add Prompt" to add a new`,
    use: `Use`,
    Close: `Close`,
    CommunityPrompts: `Community Prompts`,
    Searchprompts: `Search prompts`,
  },
  uploadDocument: {
    UploadaDocument: `Upload a Document`,
    yourquestions: `And let the Al assistant answer your questions`,
    TextInput: `Text Input`,
    Youcaninput: `You can input any kind of text format (regular text, JSON,
        CSV, raw HTML code, etc.)`,
    textplaceholder: `Paste your document here...`,
    Estimated: `Estimated`,
    tokens: `tokens`,
    supports: `GPT-3.5 model supports up to 4,096 tokens. GPT-4 model can
    support up to 32K tokens.`,
    AttachDocument: `Attach Document`,
    Cancel: `Cancel`,
    PDFUpload: `PDF Upload`,
    DragaPDFhereor: `Drag a PDF here or`,
    browse: `browse`,
    Accepttrue: `Accept true PDFs, such as those created from Word
    documents, spreadsheets, and similar formats. Scanned or
    image-only PDFs are not allowed.`,
    Onlytext: `Only text can be extracted.`,
    Ensure: `Ensure the PDF tokens fall within your model's maximum
    context length.`,
    BuyHere: `Buy Here`,
    BuyHereText: `PDF unload is not available in free trail , please buy a cense keyto unlock this feature,`,

  },
  audioInputDiaLog: {
    AudioInput: `Audio Input`,
    SpeechtoTextProvider: `Speech-to-Text Provider`,
    Listening: `Listening...`,
    Finish: `Finish`,
    Cancel: `Cancel`,
    Record: `Record`,
    startTalking: `Start Talking...`,
  },
  chatSetting: {
    TexttoSpeech: `Text to Speech`,
    SpeechAPI: `Speech API`,
    VoiceSettings: `Voice Settings`,
    Voice: `Voice`,
    Note: `Note: some voices have different character limits.`,
    Rate: `Rate`,
    Setdefault: `Set default`,
    Slower: `Slower`,
    Faster: `Faster`,
    Lower: `Lower`,
    Fasterr: `Faster`,
    Pitch: `Pitch`,
    Testaudio: `Test audio`,
    Done: `Done`,
    Play: `Play`,
    Pause: `Pause`,
    Resume: `Resume`,
    Stop: `Stop`,
  },
  chooseOpenaiKey: {
    EnterAPIKey: `Enter API Key`,
    storedlocally: ` Your API Key is stored locally on your browser and never sent
    anywhere else.`,
    OpenAIAPIKey: `OpenAI API Key`,
    GetAPIkeyhere: `Get API key here`,
    Save: `Save`,
    ChangeKey: `Change Key`,
    RemoveKey: `Remove Key`,
    tips1: ` Invalid API key. Please make sure your API key is still working
    properly.`,
    tips2: `Your API key is not working. You need a paid API account on OpenAI
    in order to use the ChatGPT API Key (the free trial won't work). To
    verify that you have a paid API account, go here and make sure you
    have your billing info added:
    https://platform.openai.com/account/billing/overview. Note that you
    do not need to have a ChatGPT Plus subscription, it's not needed. If
    you already have a paid OpenAI account, check to see if you still
    have sufficient credits. Also, try creating a new API key and trying
    again. If this problem persists, please contact support.`,
    working: `API Key not working? Click Here.`,
    Makesure: `Make sure you have your billing info addedin`,
    OpenAlBilling: `OpenAl Billing`,
    page: `page. Without billing infoyour API key will not work.`,
    Subscription: `A ChatGPT Plus Subscription is NOT needed.`,
  },
  changeVipKey: {
    EnterLicenseKey: `Enter License Key`,
    Enter: `Enter`,
    GptuneLicenseKey: `Gptune `,
    tounlock: `License Key to unlock all premium features.`,
    ChangeKey: `Change Key`,
    license_key: `license_key not found.`,
    license_key1: `This license key has reached the activation limit.`,
    Don: `Don't have one?`,
    Buy: `Buy A License Key`,
    ActivateLicense: `Activate License`,
    Unlinkthisdevice: `Unlink this device`,
    Thiswill: `This will remove your license key from this device`,
    youwill: `and you will not be able to use the premium`,
    features: `features on this device. Are you sure?`,
    Yes: `Yes, Unlink Now`,
    Cancel: `Cancel`,
    p: `Enter your license key here`,
  },
  buySuccess: {
    Thank: `Thank you for your purchase!`,
    Youwill: `You will receive a receipt and a
    license key  in your email shortly.
   Enteryour license key here to save it:`,
    p: `Enter your license key here`,
    Activate: `Activate`,
    found: `License Key not found. Please check your input and try again.`,
    found1: `This license key has reached the activation limit.`,
    activate: `I'll activate later`,
  },
  openaiKeyDiaLog:{
    EnterYourOpenAIAPIKey:`Enter Your OpenAI API Key`,
    Youneed:`You need an OpenAI API Key to use Gptune.`,
    YourAPI:`Your API Key is stored locally on your browser and never sent
    anywhere else.`,
    get:`Get your API key from Open AI dashboard`,
    tips1:`Invalid API key. Please make sure your API key is still working
    properly.`,
    tips2:`Your API key is not working. You need a paid API account on OpenAI
    in order to use the ChatGPT API Key (the free trial won't work). To
    verify that you have a paid API account, go here and make sure you
    have your billing info added:
    https://platform.openai.com/account/billing/overview. Note that you
    do not need to have a ChatGPT Plus subscription, it's not needed. If
    you already have a paid OpenAI account, check to see if you still
    have sufficient credits. Also, try creating a new API key and trying
    again. If this problem persists, please contact support.`,
    Save:`Save`,
    Cancel:`Cancel`,
  },
  searCh:{
    toclose:`to close`,
    tonavigate:`to navigate`,
    select:`select`,
    placeholder:`Type something to search`
  },
  upKey:{
    TexttoSpeech:`Text-to-Speech`,
    available:`is not available for your current plan .`,
    Please:`Please buy or upgrade your license key to unlock this feature.`,
    Upgrade:`Upgrade`,
    Cancel:`Cancel`,
  },
  uploadUserIcon:{
    Yourcharactertitle:`Your character title`,
    assistant:`The AI assistant will remember your information on all future
    chats.`,
    ProfileImageURL:`Profile Image URL`,
    Please:`Please fill in the valid url`,
    invalid:`The url path is invalid or invalid.`,
    Save:`Save`,
    Cancel:`Cancel`,
    Update:`Update`,
  },
  webApiTip:{
    tip:`Your browser does not support speech synthesis function. Please
    switch to a different browser if you want to use the speech
    synthesis feature.`
  }
};
