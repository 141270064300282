export default {
  leftDrawer: {
    NewChat: "新建聊天",
    Features: "查看所有功能",
    ChatList: "聊天列表",
    red: `免费版本不支持保存聊天记录，请购买许可证密钥以解锁所有功能。`,
    BuyHere: `立即购买`,
    Sure: `确定`,
    LicenseKey: `许可证密钥`,
    Activated: `已激活`,
    Unlicensed: `未授权`,
    APIKey: `API密钥`,
  },
  homeContentMore: {
    AbetterUIforChatGPT: `更友好的 ChatGPT 聊天体验`,
    Norepetitivelogin: `不需要重复登录`,
    Texttospeech: `语音合成`,
    PromptlibraryAIcharacters: `提示库、AI 角色`,
    Nomonthlyfee: `无月费`,
    SelectCharacter: `选择角色`,
    Alcharacters: `Al 角色`,
    PromptLibrary: `提示词库`,
    prompts: `提示`,
    UploadDocument: `上传文档`,
  },
  homeContent: {
    Reset: `重置`,
    Change: `重选`,
    Model: `模型`,
    SystemInstruction: `系统指令`,
    Play: `播放`,
    Pause: `暂停`,
    Resume: `续播`,
    Stop: `停止`,
    Settings: `设置`,
    Assistantistyping: `助手正在输入`,
    ResetText: `你可以从当前开始一个新的聊天。`,
    ForkChat: `开始新的聊天`,
    Regenerate: `重新生成`,
    Library: `提示`,
    ResetChat: `重置聊天`,
    NewChat: `新建聊天`,
    OutputLanguage: `输出语言`,
    Tone: `语气`,
    WritingStyle: `风格`,
    Format: `格式`,
    Editmessage: `编辑消息`,
    Update: `更新`,
    Cancel: `取消`,
    UpdateRegenerate: `更新并重新生成`,
    ReplaceOnly: `仅替换`,
    demonstration:`这个聊天是用于演示目的的默认示例`

  },
  buyDialog: {
    OneTimePurchase: `一次性购买`,
    Buyonceuseforever: `一次购买，永久使用！`,
    Priceincreasesoon: `价格即将上涨`,
    Standard: `标准版`,
    BasicChatFeatures: `pdf上传`,
    Savechathistory: `保存聊天记录`,
    BuyStandard: `购买标准版`,
    Extended: `扩展版`,
    allinStandard: `包含标准版全部功能`,
    TexttoSpeech: `文字转语音`,
    BuyExtended: `购买扩展版`,
    Premium: `高级版`,
    allinExtended: `包含扩展版全部功能`,
    PrioritySupport: `访问所有的新功能`,
    BuyPremium: `购买高级版`,
    Frequentlyaskedquestions: `常见问题`,
    currentplan: `当前计划`,
  },
  characterDiaLog: {
    SelectanAICharacter: `选择一个AI角色`,
    tips1: `AI角色是预先构建的机器人，你可以与AI角色聊天，AI角色是根据特定的环境定制的`,
    tips2: `训练数据和模型设置。点击“创建角色”来探索更多选项。`,
    CreateCharacter: `创建角色`,
    MyCharacters: `我的角色`,
    NoAIcharacters: `你的库中还没有AI角色。`,
    Edit: `Edit`,
    DefaultCharacter: `默认角色`,
    Close: `关闭`,
    ProfilePictureURL: `头像URL(可选)`,
    Profilemessages: `AI角色的头像将会与AI角色的消息一起显示。`,
    Title: `标题`,
    placeholderTitle: `例如，生活教练`,
    AutoFillContentwithAI: `使用 AI 自动填充内容`,
    Description: `描述`,
    placeholderDescription: `例如，一个能够帮助您设置和实现个人和专业目标的生活教练`,
    Instruction: `系统指令(适用于 AI 助手)`,
    placeholderInstruction: `例如，你是一个生活教练，你帮助用户识别和实现他们的目标，激励他们，并提供支持和鼓励。`,
    Welcomemessage: `欢迎消息`,
    placeholderWelcomemessage: `你好，我是一个生活教练。今天我怎么能帮助你?`,
    empty: `标题、描述、指令、欢迎消息不能为空`,
    AddCharacter: `添加角色`,
    Update: `更新`,
    Cancel: `取消`,
    Searchcharacters: `搜索角色`,
  },
  promptLibraryDiaLog: {
    PromptLibrary: `提示词库`,
    tips1: `提示是您可以快速填写聊天输入的消息模板。`,
    tips2: `有些提示带有变量。`,
    YourPrompts: `您的提示词`,
    AddPrompt: `添加提示词`,
    Title: `标题`,
    placeholderTitle: `提示标题(如“域名灵感”)`,
    Description: `描述`,
    placeholderDescription: `例如，基于主题的域名列表`,
    Prompt: `Prompt`,
    Usefield: `使用 {{字段 1}} {{字段 1}} {{或任何内容}} 表示空白部分。`,
    placeholderUsefield: `在此输入提示词...`,
    empty: `标题、描述、指令不能为空！`,
    Update: `更新`,
    Cancel: `取消`,
    nosavedprompts: `您没有保存的提示词。点击 "添加提示词" 添加。`,
    use: `使用`,
    Close: `关闭`,
    CommunityPrompts: `社区提示词`,
    Searchprompts: `搜索提示词`,
  },
  uploadDocument: {
    UploadaDocument: `上传文档`,
    yourquestions: `让 AI 助手回答您的问题`,
    TextInput: `文本输入`,
    Youcaninput: `您可以输入任何类型的文本格式(纯文本、JSON、CSV、HTML 代码等)`,
    textplaceholder: `在此粘贴您的文档`,
    Estimated: `预估`,
    tokens: `字符数`,
    supports: `GPT-3.5 模型最多支持 4096 tokens。GPT-4 模型最多可以支持 32K tokens。`,
    AttachDocument: `附加文件`,
    Cancel: `取消`,
    PDFUpload: `PDF 上传`,
    DragaPDFhereor: `拖动 PDF 到此处或`,
    browse: `浏览`,
    Accepttrue: `接受例如来自 Word 文档、Excel 表格或类似格式导出的PDF。不允许扫描或只使用图像的 PDF。`,
    Onlytext: `只能提取 PDF 中的文本`,
    Ensure: `确保 PDF tokens 在模型的最大上下文长度范围内。`,
    BuyHere: `立即购买`,
    BuyHereText: `PDF上传不提供免费试用，请购买密钥解锁此功能。`,
  },
  audioInputDiaLog: {
    AudioInput: `语音输入`,
    SpeechtoTextProvider: `语音转文字提供者`,
    Listening: `Listening...`,
    Finish: `完成`,
    Cancel: `取消`,
    Record: `录制`,
    startTalking: `开始对话...`,
  },
  chatSetting: {
    TexttoSpeech: `文本转语音`,
    SpeechAPI: `Speech API`,
    VoiceSettings: `语音设置`,
    Voice: `语音`,
    Note: `注意：一些声音有不同的字符限制。`,
    Rate: `语速`,
    Setdefault: `设置默认`,
    Slower: `较慢`,
    Faster: `较快`,
    Lower: `较低`,
    Fasterr: `较高`,
    Pitch: `音高`,
    Testaudio: `测试音频`,
    Done: `完成`,
    Play: `播放`,
    Pause: `暂停`,
    Resume: `续播`,
    Stop: `停止`,
  },
  chooseOpenaiKey: {
    EnterAPIKey: `输入 API 密钥`,
    storedlocally: ` 您的 API 密钥将存储在您本地的浏览器上，绝不会发送到任何其他地方。`,
    OpenAIAPIKey: `OpenAI API 密钥`,
    GetAPIkeyhere: `在此获取 API 密钥`,
    Save: `保存`,
    ChangeKey: `更改密钥`,
    RemoveKey: `删除密钥`,
    tips1: ` 无效的API密钥。请确保您的API密钥仍然有效.`,
    tips2: `您的API密钥无法使用。为了使用ChatGPT API密钥，您需要在OpenAI上拥有一个付费API账户（免费试用版无法使用）。请前往此处验证您是否有一个付费API账户，并确保已添加您的计费信息：https://platform.openai.com/account/billing/overview。请注意，您不需要拥有ChatGPT Plus订阅，这并非必需。如果您已经拥有付费OpenAI账户，请检查您是否还有足够的余额。此外，尝试创建一个新的API密钥并重试。如果问题仍然存在，请联系支持。`,
    working: `API 密钥无法工作?点击此处。`,
    Makesure: `请确保您的账单信息添加到`,
    OpenAlBilling: `OpenAl Billing`,
    page: `页面。没有账单信息，您的 API 密钥将无法正常工作。`,
    Subscription: `不需要 ChatGPT Plus 订阅。`,
  },
  changeVipKey: {
    EnterLicenseKey: `输入许可证密钥`,
    Enter: `输入`,
    GptuneLicenseKey: `Gptune `,
    tounlock: `许可证密钥,解锁所有高级功能。`,
    ChangeKey: `更改密钥`,
    license_key: `许可证密钥是无效的。`,
    license_key1: `该许可证序列号已达到激活限制。`,

    Don: `没有吗?`,
    Buy: `购买许可证密钥`,
    ActivateLicense: `激活许可证`,
    Unlinkthisdevice: `取消链接此设备`,
    Thiswill: `这将从此设备中删除您的许可证密钥，`,
    youwill: `您将无法在此设备上使用高级功能。`,
    features: `您确定吗?`,
    Yes: `是的，取消关联`,
    Cancel: `取消`,
    p: `在此处输入许可证密钥`,
  },
  buySuccess: {
    Thank: `谢谢您的购买!`,
    Youwill: `您很快会收到一封电子邮件，其中包含收据和许可证密钥。请在此处输入您的许可密钥以保存它。`,
    p: `在这里输入您的许可证密钥`,
    Activate: `激活`,
    found: `未找到许可证密钥。请检查输入并重试。`,
    found1:`该许可证序列号已达到激活限制。`,
    activate: `我稍后会进行激活。`,
  },
  openaiKeyDiaLog:{
    EnterYourOpenAIAPIKey:`请输入您的OpenAI API密钥`,
    Youneed:`您需要一个OpenAI API密钥才能使用Gptune。`,
    YourAPI:`您的API密钥仅存储在本地浏览器中，不会发送到其他任何地方。`,
    get:`请从Open AI 控制台获取您的API密钥。`,
    tips1:`无效的API密钥。请确保您的API密钥仍在正常工作。`,
    tips2:`您的API密钥无法使用。为了使用ChatGPT API密钥，您需要在OpenAI上拥有一个付费API账户（免费试用版无法使用）。请前往此处验证您是否有一个付费API账户，并确保已添加您的计费信息：https://platform.openai.com/account/billing/overview。请注意，您不需要拥有ChatGPT Plus订阅，这并非必需。如果您已经拥有付费OpenAI账户，请检查您是否还有足够的余额。此外，尝试创建一个新的API密钥并重试。如果问题仍然存在，请联系支持。`,
    Save:`保存`,
    Cancel:`取消`,
  },
  searCh:{
    toclose:`关闭`,
    tonavigate:`导航`,
    select:`选择`,
    placeholder:`输入一些内容进行搜索`
  },
  upKey:{
    TexttoSpeech:`文本转语音`,
    available:`当前计划不支持此功能。`,
    Please:`请购买或升级您的许可密钥以解锁此功能。`,
    Upgrade:`升级`,
    Cancel:`取消`,
  },
  uploadUserIcon:{
    Yourcharactertitle:`你的角色头像`,
    assistant:`未来的所有聊天中，AI助手将会记住您的信息。`,
    ProfileImageURL:`个人资料图片网址`,
    Please:`请填写有效的网址`,
    invalid:`URL路径无效`,
    Save:`保存`,
    Cancel:`取消`,
    Update:`更新`,
  },
  webApiTip:{
    tip:`您的浏览器不支持语音合成功能。如果您想使用语音合成功能，请切换到另一个浏览器。`
  }
};
