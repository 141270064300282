import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import ElementUI, { Message } from "element-ui";
import echarts from "echarts";
import mixins from "@/mixins/mixin.js";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store/index.js";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index";
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css";
//代码快捷复制
import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index";
//代码行号插件
import createHighlightLinesPlugin from "@kangc/v-md-editor/lib/plugins/highlight-lines/index";
import "@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css";
//高亮代码行
import "element-ui/lib/theme-chalk/display.css";
import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Prism from "prismjs";
import VueI18n from "vue-i18n";
import "lib-flexible";
import zh from "./assets/locales/zh-CN.js";
import en from "./assets/locales/en.js";

VMdPreview.use(vuepressTheme, {
  Prism,
  codeHighlightExtensionMap: {
    echarts: "json",
  },
});





Vue.use(VueI18n);
let Language = localStorage.getItem("$i18n") || "en";

const i18n = new VueI18n({
  locale: Language, // 默认语言
  fallbackLocale: "en", // 当前语言不存在时的回退语言
  messages: {
    "zh-CN": zh,
    en: en,
  },
});

Vue.use(Vuex);
Vue.use(VMdPreview);

Vue.prototype.$echarts = echarts;

Vue.prototype.$http = axios;
Vue.filter("tofixed", (num) => {
  return Number(num).toFixed(2);
});
Vue.config.productionTip = false;
// axios.defaults.baseURL = "https://www.wym77.com";

Vue.filter("tofixed", (num) => {
  return Number(num).toFixed(2);
});

Vue.prototype.$msg = function (msg, type = "success", offset = 300) {
  Message({
    message: msg,
    type,
    offset,
  });
};

Vue.mixin(mixins);
Vue.use(ElementUI);
Vue.config.warnHandler = (msg, instance, trace) => {};
const bus = new Vue();
Vue.prototype.$bus = bus;
new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
